'use client'

import React, { useState, useEffect, useContext, Suspense } from 'react'
import type { NextPage } from 'next'
import { useSearchParams, usePathname } from 'next/navigation'
import ModalContext from '@/app/context'
import Page from '@/components/page'
import { Header } from '@/components/header'
import ModalBase from '@/components/ModalBase'
import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { Testimonials } from '@/components/Testimonials'
import { CallToAction } from '@/components/CallToAction'
import Faqs from '@/components/faq'
import { ShinyDivider } from '@/components/ShinyDivider'
import CompanyBanner from '@/components/company-banner'
import mixpanel from 'mixpanel-browser'

const Land: NextPage = () => {
  const { openLogin, setOpenLogin } = useContext(ModalContext)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  useEffect(() => {
    if (searchParams.get('check')) {
      setIsOpen2(true)
    }
    if (searchParams.get('invite')) {
      setOpenLogin(true)
    }
  }, [])
  useEffect(() => {
    const url = `${pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`
    mixpanel.track('view_page', { name: url })
  }, [pathname, searchParams])
  return (
    <Page>
      {/* <NextSeo
        canonical="https://tinycatch.com/"
        title="Sprint Retrospectives and Retro Boards for Organizations"
        description="A framework for Agile Retrospectives that encourages growth with fun built-in. For startups and the enterprise. Try for free, no credit card required."
      /> */}
      <Header handleRegister={() => setIsOpen(true)} />
      <ModalBase
        icon={
          <PaperAirplaneIcon
            className="h-6 w-6 text-primary"
            aria-hidden="true"
          />
        }
        title={'Check your email!'}
        body={`We've sent you an email with a link to confirm your email address. If you don't see it, check your spam folder.`}
        open={isOpen2}
        setOpen={setIsOpen2}
        handleSuccess={async () => {}}
        successText={'Okay'}
      ></ModalBase>
      {/* <VideoSection /> */}
      <ShinyDivider />
      <CompanyBanner />
      <ShinyDivider />

      <Testimonials />
      <ShinyDivider />
      <CallToAction />
      <ShinyDivider />
      <Faqs />
      {/* <div className="single-faq wow fadeInUp mb-8 w-full rounded-lg border border-[#F3F4FE] bg-white p-5 sm:p-8" data-wow-delay=".2s " style={{ visibility: 'visible', animationDelay: '0.2s' }}>
        <button className="faq-btn flex w-full items-center text-left">
          <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary bg-opacity-5 text-primary">
            <svg width="17" height="10" viewBox="0 0 17 10" className="icon fill-current">
              <path d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z" fill="#3056D3" stroke="#3056D3"></path>
            </svg>
          </div>
          <div className="w-full">
            <h4 className="text-base font-semibold text-black sm:text-lg">
              Is GrayGrids part of UIdeck?
            </h4>
          </div>
        </button>
        <div className="faq-content hidden pl-[62px]">
          <div className="py-3 text-base leading-relaxed text-body-color">
            It takes 2-3 weeks to get your first blog post ready. That
            includes the in-depth research &amp; creation of your monthly
            content marketing strategy that we do before writing your
            first blog post, Ipsum available .
          </div>
        </div>
      </div> */}
      {/* <ListSection />
      <FeatureSection />
      <CasesSection />
      <SocialProof />
      <PricingTable /> */}
    </Page>
  )
}

const Wrapper = () => {
  return (
    <Suspense>
      <Land />
    </Suspense>
  )
}

export default Wrapper
