import Check from '../../constants/svg/check.tsx'
import { Accordion, AccordionItem } from '@heroui/accordion'
import { Icon } from '@iconify/react'

const faqs = [
  {
    title: 'What is TinyCatch?',
    content:
      'TinyCatch is a lightweight error monitoring solution for JavaScript/TypeScript applications, offering real-time alerts and deep insights.',
  },
  {
    title: 'How can I apply to the Open Source Discount?',
    content:
      'We love supporting open source projects. If your project is open source, reach out to us at support@tinycatch.com for a discount.',
  },
  {
    title: 'Can I use TinyCatch for my freelance projects?',
    content:
      'Absolutely! TinyCatch is perfect for both personal and commercial use. Our Free tier is great for small or single-developer projects.',
  },
  {
    title: 'What is your refund policy?',
    content:
      "We are happy to provide refunds if you're not satisfied. All we ask for is feedback! We’re happy to help you address any issues you may encounter with TinyCatch.",
  },
  {
    title: 'Can I cancel my subscription?',
    content:
      'Yes, you can cancel or renew your subscription at any time from your account settings.',
  },
  {
    title: 'Can I sign up for a yearly plan?',
    content:
      'At the moment, we do not have yearly plans. We only offer month-to-month.',
  },
]

const FAQSection = () => (
  <section className="mx-auto w-full max-w-6xl py-20 sm:px-6 sm:py-32 lg:px-8 lg:py-40">
    <div className="mx-auto flex w-full max-w-4xl flex-col items-center gap-6 lg:flex-row lg:items-start lg:gap-12">
      <h2 className="px-2 text-3xl leading-7">
        <span className="inline-block lg:hidden">FAQs</span>
        <h2 className="hidden bg-gradient-to-br from-foreground-800 to-foreground-500 bg-clip-text pt-4 text-5xl font-semibold tracking-tight text-foreground lg:inline-block">
          Frequently
          <br />
          asked
          <br />
          questions
        </h2>
      </h2>
      <Accordion
        fullWidth
        keepContentMounted
        className="gap-3"
        itemClasses={{
          base: 'px-0 sm:px-6',
          title: 'font-medium',
          trigger: 'py-6 flex-row-reverse hover:text-gray-700',
          content: 'pt-0 pb-6 text-base text-default-500',
        }}
        items={faqs}
        selectionMode="multiple"
      >
        {faqs.map((item, i) => (
          <AccordionItem
            key={i}
            indicator={<Icon icon="lucide:plus" width={24} />}
            title={item.title}
          >
            {item.content}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  </section>
)

export default FAQSection
