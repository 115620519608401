import Image from 'next/image'
import { Container } from './Container'

import avatarImage1 from '@/images/avatars/avatar-1.png'
import avatarImage2 from '@/images/avatars/avatar-2.png'
import avatarImage3 from '@/images/avatars/avatar-3.png'
import avatarImage4 from '@/images/avatars/avatar-4.png'
import avatarImage5 from '@/images/avatars/avatar-5.png'

const testimonials = [
  [
    {
      content:
        'We previously used Sentry, but TinyCatch makes error monitoring incredibly straightforward. The free trial was perfect for testing it out on a few of our mission-critical services.',
      author: {
        name: 'Martijn van Rheenen',
        role: 'Product Manager at factory14',
        image: avatarImage1,
      },
    },
    {
      content:
        'We started with our frontend teams on TinyCatch, but our product and design teams also benefit from the real-time error insights. The product simply does what it promises.',
      author: {
        name: 'Hayden James',
        role: 'Founder at LBV',
        image: avatarImage4,
      },
    },
  ],
  [
    {
      content: `I just have to say, it's refreshing how any dev—even new hires—can jump right in. TinyCatch’s interface is user-friendly, unlike some cumbersome error tracking tools we've tried in the past.`,
      author: {
        name: 'Josh Nacht',
        role: 'Director of Product at Cygnus Semi',
        image: avatarImage3,
      },
    },
    {
      content: `The free trial was a no-brainer. We caught a critical bug on day one with zero setup hassle. I'd recommend TinyCatch to any engineering team.`,
      author: {
        name: 'Marius Baker',
        role: 'CPO at re:cap',
        image: avatarImage2,
      },
    },
  ],
  [
    {
      content: `TinyCatch makes it so easy to pinpoint issues without sifting through endless logs. It's saved us countless hours and kept our users happy.`,
      author: {
        name: 'Monica Bloom',
        role: 'Founder at Perpetual Next',
        image: avatarImage5,
      },
    },
    {
      content: `Every time I join a new project, I recommend switching to TinyCatch for error monitoring. It's consistently delivered a superior developer experience compared to other tools.`,
      author: {
        name: 'Jack Green',
        role: 'Engineering Manager at Fraction',
        image: avatarImage4,
      },
    },
  ],
]

function QuoteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

export function Testimonials() {
  return (
    <section
      id="testimonials"
      aria-label="What our customers are saying"
      className="py-20 sm:py-32 bg-white"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-gray-900 sm:text-4xl">
            Trusted by Teams Worldwide
          </h2>
          <div className="mt-4 text-lg tracking-tight text-gray-700">
            We’re dedicated to simplifying error monitoring so you can focus on
            delivering great user experiences. Here’s what our customers have to
            say.
          </div>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 border border-gray-200">
                      <div className="text-gray-200">
                        <QuoteIcon
                          className="absolute top-6 left-6"
                          fill="currentColor"
                          opacity={0.8}
                        />
                      </div>
                      <blockquote className="relative">
                        <div className="text-lg tracking-tight text-gray-900">
                          {testimonial.content}
                        </div>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-gray-100 pt-6">
                        <div>
                          <div className="font-display text-base text-gray-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-gray-600">
                            {testimonial.author.role}
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-gray-50 h-14 w-14 object-cover">
                          <Image
                            className="object-cover"
                            src={testimonial.author.image}
                            alt=""
                            width={56}
                            height={56}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
